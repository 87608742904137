



























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CalendarItemWrapper extends Vue {
    @Prop({
        type: Boolean,
        default: true,
    })
    private hoverable!: boolean;
}
